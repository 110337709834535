import { useCompanyIDAndProjectID } from "../../helpers/utils";
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { createDocumentTypeKey, HasDocTypeFieldsWithName } from "components/documents/documentUtils";
import { LabeledValue } from "antd/es/select";
import useDocumentTypes from "hooks/data/useDocumentTypes";
import { DocumentTypesQuery_project_documentTypes } from "__generated__/DocumentTypesQuery";

interface DocumentTypeSelectProps {
  /** a map of document type keys to a number which will be put in parathesis */
  documentTypeCounts?: Map<string, number>;
  /** functionality for src/pages/company/[companyID]/project/[projectID]/settings/templates.tsx */
  displayOnlyDocTypeIDs?: string[];
  /** optionally include an extra dropdown option at the time with name {string} */
  extraOption?: LabeledValue & { onChange: VoidFunction };
  /** filter callback.   */
  onChange(val?: HasDocTypeFieldsWithName): void;
  /** display only drawing-related document types? */
  onlyDrawings?: boolean;
  selected: NullableAndOptional<Pick<HasDocTypeFieldsWithName, "documentType" | "customTypeId">>;
}

/**
 * Renders a document type select dropdown
 * @todo not happy with how many times we call `createDocumentTypeKey()` here; ideally the backend should give this to use in an `id` field
 */
export const DocumentTypeSelect = ({
  documentTypeCounts,
  displayOnlyDocTypeIDs,
  extraOption,
  onChange,
  selected,
  onlyDrawings = false,
}: DocumentTypeSelectProps) => {
  const { projectID } = useCompanyIDAndProjectID();
  const DocumentTypesData = useDocumentTypes(projectID!, { onlyDrawings });
  let { docTypes } = DocumentTypesData;
  const { loading } = DocumentTypesData;
  const [selectedDocType, setSelectedDocType] = useState<Optional<DocumentTypesQuery_project_documentTypes>>(
    selected as unknown as DocumentTypesQuery_project_documentTypes
  );

  // call onChange() callback when the selected document type has changed
  useEffect(() => {
    onChange(selectedDocType);
  }, [selectedDocType]);

  // guard against displaying a document type that is not included in the docTypes array
  useEffect(() => {
    if (!selectedDocType) return;
    const currentlySelectedDocTypeKey = createDocumentTypeKey(selectedDocType);
    if (
      docTypes.findIndex((dt) => {
        const key = createDocumentTypeKey(dt);
        return currentlySelectedDocTypeKey === key;
      }) === -1
    ) {
      // the currently selected doc type is not included in the docTypes array. clear selection:
      setSelectedDocType(undefined);
    }
  }, [docTypes]);

  if (displayOnlyDocTypeIDs) {
    docTypes = docTypes.filter((d) => displayOnlyDocTypeIDs.includes(createDocumentTypeKey(d)!));
  }

  const docTypeOptionsJSX = docTypes.map((d: DocumentTypesQuery_project_documentTypes) => {
    const key = createDocumentTypeKey(d);
    if (!key) throw new Error("expected key");
    return (
      <Select.Option key={key} name={d.name} value={key}>
        {d.name} {typeof documentTypeCounts !== "undefined" && documentTypeCounts.get(key || "0")}
      </Select.Option>
    );
  });

  if (loading) return null;

  return (
    <Select
      autoFocus
      defaultActiveFirstOption={true}
      filterOption={(input, option) => {
        // filter options via keyboard entry
        if (!option) return false;
        return (option.name as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      onChange={(key) => {
        if (extraOption && extraOption.value === key) extraOption.onChange();
        const docTypeObj = docTypes.find((d) => createDocumentTypeKey(d) === key);
        if (!docTypeObj) throw new Error("could not find selected document type by key " + key);
        setSelectedDocType(docTypeObj);
      }}
      placeholder="Select a document type"
      showSearch
      style={{ width: "100%" }}
      value={createDocumentTypeKey(selectedDocType)}
    >
      {extraOption ? <Select.Option value={extraOption.value}>{extraOption.label}</Select.Option> : null}
      {docTypeOptionsJSX}
    </Select>
  );
};
