import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as apolloUseQuery,
} from "@apollo/client";
import { useRouter } from "next/router";
import useIdleTimer from "./useIdleTimer";

/**
 * This wrapper exists to simplify our usage of Apollo's useQuery() hook.
 * https://www.apollographql.com/docs/react/api/react/hooks/#usequery
 *
 * Differences:
 * 1) Apollo's built-in `useQuery()` can be problematic when SSR'ed or when the `skip` option is truthy.
 *    With this wrapper, if `loading` is falsy, you can assume we either have data, or an error occured.
 *
 * 2) Idle detection
 *    startPolling() and stopPolling() are called automatically via an idle timer.
 */
export default function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const router = useRouter();

  // do not run queries until after prerender and the router fields are ready for use
  if ((!isRunningWithinJestTest() && typeof window === "undefined") || !router.isReady) {
    options = { ...options, skip: true };
  }

  if (!options?.fetchPolicy) {
    // if a fetch policy is not specified, we want to make sure we're using cache-first
    // although cache-first is Apollo's default fetchPolicy, Apollo is so intrinsic to our setup it feels important to explicitly set this.
    options = { ...options, fetchPolicy: "cache-first" };
  }

  const result = apolloUseQuery<TData, TVariables>(query, options);

  const maybeStartPolling = () => {
    if (options?.pollInterval) {
      result.startPolling(options.pollInterval);
    }
  };

  useIdleTimer({ onActive: maybeStartPolling, onIdle: result.stopPolling });

  if ((!isRunningWithinJestTest() && typeof window === "undefined") || options?.skip === true) {
    result.loading = true;
  }

  return result;
}

/**
 * Helper function to determine whether code is being called within a Jest test.
 */
function isRunningWithinJestTest() {
  return process.env.JEST_WORKER_ID !== undefined;
}
