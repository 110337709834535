import moment from "moment";
import { useRouter } from "next/router";
import fileSaver from "file-saver";
import { JSONCrush, JSONUncrush } from "third_party/JSONCrush";
import Pluralize from "pluralize";
import _ from "lodash";
import { useEffect } from "react";

/**
 * Stringify an object into a URL safe query string
 */
export function encodeForQS(data: any) {
  return JSONCrush(JSON.stringify(data));
}

/**
 * Parse a query string into an object
 */
export function decodeFromQS(encoded: string) {
  if (!encoded) return;
  return JSON.parse(JSONUncrush(decodeURIComponent(encoded)));
}

/**
 * @returns The query string parsed to an object, with companyID and projectID REMOVED.
 */
export function useQS() {
  const router = useRouter();
  const query = { ...router.query };
  delete query["companyID"];
  delete query["projectID"];
  return query;
}

/**
 * @returns companyID and projectID from URL
 */
export function useCompanyIDAndProjectID() {
  const router = useRouter();
  const companyID = router.query.companyID as Optional<string>;
  const projectID = router.query.projectID as Optional<string>;
  return { companyID, projectID };
}

/**
 * Returns the base path for a company and project (if a project is selected)
 */
export function useBasePath() {
  const { companyID, projectID } = useCompanyIDAndProjectID();
  if (projectID) {
    return `/company/${companyID}/project/${projectID}`;
  }
  return `/company/${companyID}`;
}

/**
 * "Toggles" an item in an array.
 * ADDS the item if it does not exist in the array.
 * REMOVES the item if it exists in the array.
 * @returns Cloned array, with the element added or removed.
 */
export function addOrRemove<T>(array: T[], item: T) {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  } else {
    return [...array, item];
  }
}

export function selectItemsBetween(allItemIds: string[], selectionIds: string[], targetId: string) {
  // Get the ID of the last document selected before this event
  const [lastSelectedId] = selectionIds.slice(-1);

  // If no other documents have been selected prior, select new document
  if (!lastSelectedId) {
    return [targetId];
  }

  const indexOfLastSelected = allItemIds.indexOf(lastSelectedId);
  const indexOfNewlySelected = allItemIds.indexOf(targetId);

  const selectionBounds = [indexOfLastSelected, indexOfNewlySelected];
  selectionBounds.sort((a, b) => a - b);
  const [startIndex, endIndex] = selectionBounds;

  return allItemIds.slice(startIndex, endIndex + 1).reduce(
    (selection, itemID) => {
      const isAlreadySelected = selection.includes(itemID);
      if (isAlreadySelected) {
        return selection;
      }

      return [...selection, itemID];
    },
    [...selectionIds]
  );
}

/**
 * Recursively test for an empty object.
 * @param obj Object to be tested
 * @returns true if empty, else false
 */
export function isEmptyObject(obj: AnyObject) {
  if (!obj) return true;
  return obj.constructor.name === "Object"
    ? Object.keys(obj).reduce((x, y) => x && isEmptyObject(obj[y]), true)
    : obj.length == 0;
}

/**
 * Similar to React's useState() hook, but stores state by mutating the query string.
 * @example [name, setName] = useStatefulURL('usersName')  // NOTE: 'usersName' is the key used in the query string (NOT the initial value)
 * @param key key used in querystring
 * @returns [value, setValue]
 */
export function useStatefulURL(key: string): [Optional<string>, (nextValue: Optional<string>) => Promise<boolean>] {
  const router = useRouter();
  const getValue = () => {
    return router.query[key] as Optional<string>;
  };
  const setValue = async (nextValue: Optional<string>) => {
    if (!router.isReady) {
      throw new Error("router is not ready");
    }
    const { pathname, query } = router;
    if (nextValue) {
      query[key] = nextValue;
    } else {
      delete query[key];
    }
    return router.push({ pathname, query });
  };
  let value = getValue();
  useEffect(() => {
    value = getValue();
  }, [router.isReady]);
  return [value, setValue];
}

/**
 * Sorts by date, descending unless the ascending parameter is true
 * @param arr Array of objects
 * @param key Key to find the date in the object
 * @param ascending If set to true, sorts in ascending order
 */
export function dateSort<T extends AnyObject>(arr: T[], key: string, ascending?: boolean) {
  arr.sort((a, b) => {
    if (moment(a[key]).isAfter(moment(b[key]))) return ascending ? 1 : -1;
    else if (moment(a[key]).isBefore(moment(b[key]))) return ascending ? -1 : 1;
    else return 0;
  });
}

/**
 * @returns if Mixpanel's ENV variable has been set, true.  Else, false.
 */
export function isMixpanelEnabled() {
  return typeof process.env.NEXT_PUBLIC_MIXPANEL_TOKEN !== "undefined";
}

/**
 * When used in a page's getStaticProps(), requires ENABLE_DADO_ENV=yes to be set at build time for the page to be built.
 * Otherwise, the page will not be built and the route will 404.
 *
 * @example
 * export const getStaticProps = async () => {
 *   return getStaticProps_requireDadoDevtoolsToBeEnabled();
 * };
 */
export function getStaticProps_requireDadoDevtoolsToBeEnabled() {
  if (process.env.ENABLE_DADO_DEVTOOLS !== "yes") {
    return {
      notFound: true, // https://nextjs.org/blog/next-10#notfound-support
    };
  }
  // next is expecting a props object, so return an empty one:
  return { props: {} };
}

/**
 * Saves the given text as a file on our user's computer.
 * @param filename File name.
 * @param mimetype Mimetype.  E.g., `text/csv`.  `;charset=utf-8` is always appended.
 * @param text Data to be saved.
 */
export function saveTextAsFile(filename: string, mimetype: string, text: string) {
  const blob = new Blob([text], { type: mimetype + ";charset=utf-8" });
  fileSaver.saveAs(blob, filename);
}

/**
 * Returns true if the given character is a letter or a number, else false
 */
export function isLetterOrNumber(char: string) {
  return /^[a-z0-9]$/i.test(char);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function searchInObjectsKeys<T extends object>(
  obj: T[],
  searchTerm: string,
  keys?: RecursiveKeyOf<T>[],
  caseSensitive = false
): T[] {
  if (!caseSensitive) {
    searchTerm = searchTerm.toLowerCase();
  }
  searchTerm = caseSensitive ? searchTerm : searchTerm.toLowerCase();
  return obj.filter((item) => {
    const objKeys = typeof keys !== "undefined" ? keys : Object.keys(item);
    return objKeys.some((key) => {
      const value = _.get(item, key);
      const str = caseSensitive ? value : String(value).toLowerCase();
      return str.includes(searchTerm);
    });
  });
}

export const parseSectionID = (id: string) => {
  // See api:parseDocumentSectionId as source of truth on parsing section id.
  const [docTypeCode, projectId, md5, rawPageRanges] = id.split("|");
  let pageRanges: { begin: number; end: number };
  if (rawPageRanges.includes("-")) {
    const [begin, end] = rawPageRanges.split("-");
    pageRanges = { begin: Number(begin), end: Number(end) };
  } else {
    pageRanges = { begin: Number(rawPageRanges), end: Number(rawPageRanges) };
  }
  return { docTypeCode, projectId, md5, pageRanges };
};

export const camelCaseToCamelCaseWithSpaces = (str = "") => {
  const match = str.match(/[A-Z][^A-Z]*/g);
  return match ? match.join(" ") : str || "";
};

// https://stackoverflow.com/a/56768137
export function getUniqueListBy<T>(arr: T[], key: keyof T) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

// TODO check whether the below functions are used:

export const mapToObject = (map: Map<string, any>) => {
  const obj: any = {};
  map.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

export const indicateIOSDevice = () => {
  const userAgentSettings = window.navigator.userAgent.toLowerCase();
  return (
    userAgentSettings.indexOf("iphone") > -1 ||
    userAgentSettings.indexOf("ipad") > -1 ||
    (userAgentSettings.indexOf("macintosh") > -1 && "ontouchend" in document)
  );
};

export const indicateAndroidDevice = () => {
  const userAgentSettings = window.navigator.userAgent.toLowerCase();
  return userAgentSettings.indexOf("android") > -1;
};

export const indicateMobileDevice = () => {
  return indicateIOSDevice() || indicateAndroidDevice();
};

export const isIsoDate = (value: NullableAndOptional<string>) => {
  if (value) {
    return Date.parse(value.toString());
  }
  return false;
};

export const toDefaultDate = (isoDate?: NullableAndOptional<string>, defaultToday = false) => {
  const FORMAT = "MMM DD, YYYY";
  if (isoDate && isIsoDate(isoDate)) {
    return moment(isoDate as string).format(FORMAT);
  } else if (defaultToday) {
    return moment().format(FORMAT);
  }
};

export const ellipsis = (value: string, length = 75): string => {
  value = value || "";
  const suffix = value.length > length ? " ..." : "";
  return `${value.substring(0, length)}${suffix}`;
};

/**
 * Wrapper around proper english accurate pluralizer
 * You can give it any word, even if its already plural.
 * Pass true to 3rd parameter to also output the number too.
 */
export const pluralize = (
  phrase: string, // anything
  count: number,
  outputNumber?: boolean
): string => {
  if (Pluralize.isPlural(phrase)) {
    phrase = Pluralize.singular(phrase);
  }
  return Pluralize(phrase, count, outputNumber); // real pluralizer
};

// Inspired by: https://gitlab.com/projectdado/code/web-app/-/merge_requests/54#note_485723950
export const omitDeep = (obj: any, key: string) => {
  const keys = Object.keys(obj);
  const newObj: any = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) {
        newObj[i] = omitDeepArrayWalk(val, key);
      } else if (typeof val === "object" && val !== null) {
        newObj[i] = omitDeep(val, key);
      } else {
        newObj[i] = val;
      }
    }
  });
  return newObj;
};

const omitDeepArrayWalk = (arr: any, key: string) => {
  return arr.map((val: any) => {
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key);
    } else if (typeof val === "object" && val !== null) {
      return omitDeep(val, key);
    }
    return val;
  });
};

interface HasName {
  name: string;
}

export const sortByName = (a: HasName, b: HasName) => {
  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
};

/**
 * Displays string with elipses if over a certain length
 * @param str
 * @param maxLength
 */
export function strMaxLengthWithElipses(str: string, maxLength: number) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "...";
  }
  return str;
}

export const isValidRegex = (expression) => {
  if (expression === "") return false;
  try {
    new RegExp(expression);
    return true;
  } catch (e) {
    return false;
  }
};

export function toPercent(num: number, denom: number): string {
  if (denom === 0) {
    return "0%";
  } else {
    return `${Math.round((num * 100) / denom)}%`;
  }
}

// Inspired by https://stackoverflow.com/questions/1189128/regex-to-extract-subdomain-from-url
export const extractSubdomain = (val: string): string => {
  /* eslint-disable */
  const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
  const match = subdomainRegex.exec(val);
  return match && match.length > 1 ? match[1] : "";
};

// Converts hex string to RGBA dictionary. Designed for use w/ PDFTron compare feature.
export const hexToDiffColorObject = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        R: parseInt(result[1], 16),
        G: parseInt(result[2], 16),
        B: parseInt(result[3], 16),
        A: 0,
      }
    : {
        R: 0,
        G: 0,
        B: 0,
        A: 0,
      };
};

/**
 * Returns an array with only one occurence of every item
 * @param array
 */
export const uniqueArray = <T extends Primitive>(array: Array<T>) => {
  return [...new Set(array)];
};

/*
 * Returns a parsed array of a string number range
 * @param string
 */
// https://github.com/euank/node-parse-numeric-range/
export const parseNumericRange = (string: string) => {
  let res: number[] = [];
  let m: Nullable<RegExpMatchArray>;

  for (let str of string.split(",").map((str) => str.trim())) {
    // just a number
    if (/^-?\d+$/.test(str)) {
      res.push(parseInt(str, 10));
    } else if ((m = str.match(/^(-?\d+)(-|\.\.\.?|\u2025|\u2026|\u22EF)(-?\d+)$/))) {
      // 1-5 or 1..5 (equivalent) or 1...5 (doesn't include 5)
      const [_, lhs, sep, rhs] = m;

      if (lhs && rhs) {
        const numberLeft = parseInt(lhs);
        let numberRight = parseInt(rhs);
        const incr = numberLeft < numberRight ? 1 : -1;

        // Make it inclusive by moving the right 'stop-point' away by one.
        if (sep === "-" || sep === ".." || sep === "\u2025") numberRight += incr;

        for (let i = numberLeft; i !== numberRight; i += incr) res.push(i);
      }
    }
  }

  return uniqueArray(res);
};

/**
 * Trigger a download in the browser from a URL as if you clicked a download button
 * Just pass in the url and the desired file name (server can override this)
 */
export const triggerFileDownload = (url: string, filename: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  a.download = filename;
  a.href = url;
  a.click();
};

type Dimensions = {
  height: number;
  width: number;
};

/**
 * Will return the largest dimensions proportional to the childDimensions that will fit within the parent
 */
export const getConstrainedDimensions = (parentDimensions: Dimensions, childDimensions: Dimensions): Dimensions => {
  if (childDimensions.width > parentDimensions.width) {
    const reducedDimensions = {
      height: (childDimensions.height / childDimensions.width) * parentDimensions.width,
      width: parentDimensions.width,
    };
    return getConstrainedDimensions(parentDimensions, reducedDimensions);
  }

  if (childDimensions.height > parentDimensions.height) {
    const reducedDimensions = {
      height: parentDimensions.height,
      width: (childDimensions.width / childDimensions.height) * parentDimensions.height,
    };
    return getConstrainedDimensions(parentDimensions, reducedDimensions);
  }

  return childDimensions;
};
