// eslint-disable-next-line no-restricted-imports
import { useIdleTimer as useOriginalUseIdleTimer } from "react-idle-timer";

/**
 * Drop-in replacement for `useIdleTimer` with DADO defaults
 * https://www.npmjs.com/package/react-idle-timer
 */
export default function useIdleTimer(props: Parameters<typeof useOriginalUseIdleTimer>[0]) {
  if (!props.timeout) {
    props.timeout = 1000 * 60 * 10; // 10 minutes in milliseconds
  }
  return useOriginalUseIdleTimer(props);
}
